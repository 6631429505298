<template>
  <div>
    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Yakıtpay</div>
      <div class="right">
        <a href="tel:08502511919" class="headerButton">
          <ion-icon name="call-outline"></ion-icon>
        </a>
      </div>
    </div>
    <!-- * App Header -->
    <!-- App Capsule -->
    <div id="appCapsule">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d24087.158977698404!2d29.11854135502705!3d41.00567413522402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1spetrol%20ofisi!5e0!3m2!1str!2str!4v1679355559609!5m2!1str!2str"
        width="410"
        height="680"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <!-- * App Capsule -->
    </div>
    <!-- App Bottom Menu -->
    <div class="section full mt-2">
      <!-- App Bottom Menu -->

      <div class="appBottomMenu">
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/mainView/' + userDatax.objectId }">
              <ion-icon name="home-outline"></ion-icon>
              <strong>Anasayfa</strong>
            </router-link>
          </div>
        </a>
        <a class="item active">
          <div class="col">
            <router-link :to="{ path: '/mapView/' }">
              <ion-icon name="navigate-outline"></ion-icon>
              <strong>İstasyonlar</strong>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <div class="action-button large danger">
              <!--   <ion-icon name="add-outline"></ion-icon>-->
              <img
                src="../../../public/frontend/assets/img/po.png"
                alt="image"
              />
            </div>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/carsView/' + userDatax.objectId }">
              <ion-icon name="car-sport-outline"></ion-icon>
              <strong>Araçlarım</strong>
              <span class="badge badge-danger">{{ this.countCarsResult }}</span>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/cardsView/' + userDatax.objectId }">
              <ion-icon name="card-outline"></ion-icon>

              <strong>Kartlarım</strong>
            </router-link>
          </div>
        </a>
      </div>
      <!-- * App Bottom Menu -->
    </div>

    <!-- * App Bottom Menu -->

    <!-- App Sidebar -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
      <div class="offcanvas-body">
        <!-- profile box -->
        <div class="profileBox">
          <div class="image-wrapper">
            <img
              src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
              alt="image"
              class="imaged rounded"
            />
          </div>
          <div class="in">
            <strong>{{ cariData.adSoyad }}</strong>
            <div class="text-muted"></div>
          </div>
          <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <!-- * profile box -->

        <ul class="listview flush transparent no-line image-listview mt-2">
          <li>
            <a class="item" href="#" @click.prevent="profilview">
              <div class="icon-box bg-danger">
                <ion-icon name="person-outline"></ion-icon>
              </div>
              <div class="in">Profil</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="fuelview">
              <div class="icon-box bg-danger">
                <ion-icon name="speedometer-outline"></ion-icon>
              </div>
              <div class="in">Yakıt Alımları</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="cardsview">
              <div class="icon-box bg-danger">
                <ion-icon name="card-outline"></ion-icon>
              </div>
              <div class="in">Kredi Kartı Bilgileri</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="carsview">
              <div class="icon-box bg-danger">
                <ion-icon name="car-outline"></ion-icon>
              </div>
              <div class="in">
                Araç Bilgileri
                <span class="badge badge-danger">{{
                  this.countCarsResult
                }}</span>
              </div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="callUs">
              <div class="icon-box bg-danger">
                <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
              </div>
              Bize Ulaşın
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="logoutUser">
              <div class="icon-box bg-danger">
                <ion-icon name="exit-outline"></ion-icon>
              </div>
              Çıkış
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- * App Sidebar -->
  </div>
</template>

<script>
import Parse from "parse";
// @ is an alias to /src
export default {
  name: "MainView",

  components: {},
  data() {
    return {
      countCarsResult: 0,
      userInfoResult: [],
      cariData: "",
      info: "",
      cash: true,
      userDatax: [],
    };
  },

  methods: {
    goBack() {},
    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/"); //DİKKAT
    },

    mainView() {
      //window.location = "/mainVew/" + this.userDatax.objectId;

      this.$router.push({ path: `/mainView/${this.userDatax.objectId}` });
    },

    callUs() {
      //window.location = "/contactview";
      this.$router.push({ path: `/contactview` });
    },

    carsview() {
      //window.location = "/carsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/carsView/${this.userDatax.objectId}` });
    },

    cardsview() {
      //window.location = "/cardsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/cardsView/${this.userDatax.objectId}` });
    },

    fuelview() {
      //window.location = "/fuelView/" + this.userDatax.objectId;
      this.$router.push({ path: `/fuelView/${this.userDatax.objectId}` });
    },
    profilview() {
      //window.location = "/profilView/" + this.userDatax.objectId;
      this.$router.push({ path: `/profilView/${this.userDatax.objectId}` });
    },
    cashDeposit() {
      console.log("para yatırma");
      this.cash = false;
      var myOffcanvas = document.getElementById("addMoneyForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();
    },
  },
  beforeMount() {
    var userData = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userData.cariKod);
    console.log(userData.objectId);
    this.userDatax = userData;

    console.log("tablo yükleme başladımı");
    const carparams = { cariKod: userData.cariKod };
    const userparams = { cariKod: userData.cariKod };
    console.log(carparams);
    console.log(userparams);

    (async () => {
      console.log("carcount geldi mi");
      try {
        this.countCarsResult = await Parse.Cloud.run("countCars", carparams);
        console.log("countCars   :::  " + this.countCarsResult);
      } catch (error) {
        console.error("Error while retrieving object countCars", error);
      }
    })();

    (async () => {
      console.log("userInfo geldi mi");
      try {
        this.userInfoResult = await Parse.Cloud.run("cariInfo", userparams);
        console.log("UserInfo   :::  " + this.userInfoResult);
        this.cariData = this.userInfoResult[0];

        //console.log("UserInfo   :::  " + this.userInfoResult.ticariUnvan);
      } catch (error) {
        console.error("Error while retrieving object userInfo", error);
      }

      this.infom = JSON.stringify(this.cariData);
      console.log("infom: " + this.infom);
      localStorage.setItem("Info", JSON.stringify(this.cariData));
      console.log("cariData :: " + JSON.stringify(this.cariData));
      this.cariData = JSON.parse(JSON.stringify(this.cariData));
      console.log("cariData unvan :: " + this.cariData.cariKod);
    })();
  },
};
</script>
