<template>
  <div>
    <!-- /loader_form -->

    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Yakıtpay</div>
      <div class="right">
        <a href="tel:08502511919" class="headerButton">
          <ion-icon name="call-outline"></ion-icon>
        </a>
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="section mt-2 mb-3">
        <div class="profile-head">
          <div class="avatar">
            <img
              src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
              alt="avatar"
              class="imaged w64 rounded"
            />
          </div>
          <div class="in">
            <h3 class="name">{{ this.cariData.ticariUnvan }}</h3>
          </div>
        </div>
      </div>

      <!---->

      <div class="section full">
        <div class="wide-block transparent p-0">
          <ul class="nav nav-tabs lined iconed" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#kisisel"
                role="tab"
              >
                <ion-icon name="people-outline"></ion-icon> Kişisel Bilgileri
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#iletisim"
                role="tab"
              >
                <ion-icon name="grid-outline"></ion-icon>İletişim Bilgileri
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#fatura"
                role="tab"
              >
                <ion-icon name="bookmark-outline"></ion-icon>Fatura Bilgileri
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- tab content -->
      <div class="section full mb-1">
        <div class="tab-content">
          <!-- Kisisel Bilgiler -->
          <div class="tab-pane fade show active" id="kisisel" role="tabpanel">
            <div class="mt-2 p-2 pt-0 pb-0">
              <form class="needs-validation" novalidate>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="name1">Ad soyad</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name1"
                      placeholder="Adınız soyadınız"
                      required
                      v-model="this.cariData.adSoyad"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">
                      Lütfen Soyadınızı giriniz.
                    </div>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="lastname1">Adres</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname1"
                      placeholder="Adresiniz"
                      required
                      v-model="this.cariData.address"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">Lütfen adres giriniz.</div>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="city4">Şehir</label>
                    <input
                      type="text"
                      class="form-control"
                      id="city4"
                      placeholder="Şehir"
                      required
                      v-model="this.cariData.city"
                    />

                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">Lütfen Şehir Seciniz.</div>
                  </div>
                </div>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="city4">Kampanya</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Kampanya5"
                      placeholder="Kampanya Kodu"
                      required
                      v-model="this.cariData.kampanya"
                    />

                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">Lütfen Şehir Seciniz.</div>
                  </div>
                </div>

                <button class="btn btn-danger btn-block" type="submit">
                  Güncelle
                </button>
              </form>
            </div>
          </div>
          <!-- * Kişisel Bilgiler-->

          <!-- * İletişim Bilgileri-->
          <div class="tab-pane fade" id="iletisim" role="tabpanel">
            <div class="mt-2 p-2 pt-0 pb-0">
              <form class="needs-validation" novalidate>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="name1">Telefon</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="name1"
                      placeholder="Telefon Numaranız"
                      required
                      v-model="this.cariData.gsmNo"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">
                      Lütfen telefon numarası giriniz.
                    </div>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="lastname1">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname1"
                      placeholder="Email adresiniz"
                      required
                      v-model="this.cariData.email"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">Lütfen email giriniz.</div>
                  </div>
                </div>

                <div class="form-group mt-2 mb-3">
                  <div class="form-check mb-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="customCheckb1"
                      required
                    />
                    <label class="form-check-label" for="customCheckb1"
                      >İletişim izni</label
                    >
                  </div>
                  <div class="valid-feedback">Looks good!</div>
                  <div class="invalid-feedback">
                    Lütfen iletişim iznini onaylayınız.
                  </div>
                </div>

                <button class="btn btn-danger btn-block" type="submit">
                  Güncelle
                </button>
              </form>
            </div>
          </div>

          <!-- * İletişim Bilgiler-->

          <!--  fatura -->
          <div class="tab-pane fade" id="fatura" role="tabpanel">
            <div class="mt-2 p-2 pt-0 pb-0">
              <form class="needs-validation" novalidate>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="ticari1">Ticari Ünvan</label>
                    <input
                      type="text"
                      class="form-control"
                      id="ticari1"
                      placeholder="ticari ünvanızı giriniz"
                      required
                      v-model="this.cariData.ticariUnvan"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">
                      Lütfen ticari ünvanı giriniz.
                    </div>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="vkn1">Vergi No / TCKN</label>
                    <input
                      type="text"
                      class="form-control"
                      id="vkn1"
                      placeholder="Vergi numaranız"
                      required
                      v-model="this.cariData.vergiNo"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">
                      Lütfen vergi numaranızı giriniz.
                    </div>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="lastname1">Vergi Dairesi</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname1"
                      placeholder="Vergi dairesi"
                      required
                      v-model="this.cariData.vDairesi"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div class="valid-feedback">Looks good!</div>
                    <div class="invalid-feedback">
                      Lütfen Soyadınızı giriniz.
                    </div>
                  </div>
                </div>

                <button class="btn btn-danger btn-block" type="submit">
                  Güncelle
                </button>
              </form>
            </div>
          </div>
          <!-- * Fatura -->
        </div>
      </div>
      <!-- * tab content -->

      <!-- App Bottom Menu -->
      <div class="section full mt-2">
        <!-- App Bottom Menu -->

        <div class="appBottomMenu">
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/mainView/' + userDatax.objectId }">
                <ion-icon name="home-outline"></ion-icon>
                <strong>Anasayfa</strong></router-link
              >
            </div>
          </a>
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/mapView/' }">
                <ion-icon name="navigate-outline"></ion-icon>
                <strong>İstasyonlar</strong></router-link
              >
            </div>
          </a>
          <a class="item">
            <div class="col">
              <div class="action-button large danger">
                <!--   <ion-icon name="add-outline"></ion-icon>-->
                <img
                  src="../../../public/frontend/assets/img/po.png"
                  alt="image"
                />
              </div>
            </div>
          </a>
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/carsView/' + userDatax.objectId }">
                <ion-icon name="car-sport-outline"></ion-icon>
                <strong>Araçlarım</strong></router-link
              >
              <span class="badge badge-danger">{{ this.countCarsResult }}</span>
            </div>
          </a>
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/cardsView/' + userDatax.objectId }">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Kartlarım</strong></router-link
              >
            </div>
          </a>
        </div>
        <!-- * App Bottom Menu -->
      </div>

      <!-- App Sidebar -->
      <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
        <div class="offcanvas-body">
          <!-- profile box -->
          <div class="profileBox">
            <div class="image-wrapper">
              <img
                src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
                alt="image"
                class="imaged rounded"
              />
            </div>
            <div class="in">
              <strong>{{ cariData.adSoyad }}</strong>
              <div class="text-muted"></div>
            </div>
            <a
              href="#"
              class="close-sidebar-button"
              data-bs-dismiss="offcanvas"
            >
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
          <!-- * profile box -->

          <ul class="listview flush transparent no-line image-listview mt-2">
            <li>
              <a class="item" href="#" @click.prevent="profilview">
                <div class="icon-box bg-danger">
                  <ion-icon name="person-outline"></ion-icon>
                </div>
                <div class="in">Profil</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="fuelview">
                <div class="icon-box bg-danger">
                  <ion-icon name="speedometer-outline"></ion-icon>
                </div>
                <div class="in">Yakıt Alımları</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="cardsview">
                <div class="icon-box bg-danger">
                  <ion-icon name="card-outline"></ion-icon>
                </div>
                <div class="in">Kredi Kartı Bilgileri</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="carsview">
                <div class="icon-box bg-danger">
                  <ion-icon name="car-outline"></ion-icon>
                </div>
                <div class="in">
                  Araç Bilgileri
                  <span class="badge badge-danger">{{
                    this.countCarsResult
                  }}</span>
                </div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="callUs">
                <div class="icon-box bg-danger">
                  <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                </div>
                Bize Ulaşın
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="logoutUser">
                <div class="icon-box bg-danger">
                  <ion-icon name="exit-outline"></ion-icon>
                </div>
                Çıkış
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- * App Sidebar -->
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import axios from "axios";

export default {
  name: "ProfilView",

  components: {},

  data() {
    return {
      countCarsResult: 0,
      userInfoResult: [],
      cariData: "",
      info: "",
      cash: true,
      userDatax: [],
    };
  },

  methods: {
    goBack() {
      //window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },

    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/"); //DİKKAT
    },

    mainView() {
      //window.location = "/mainVew/" + this.userDatax.objectId;

      this.$router.push({ path: `/mainView/${this.userDatax.objectId}` });
    },

    callUs() {
      //window.location = "/contactview";
      this.$router.push({ path: `/contactview` });
    },

    carsview() {
      //window.location = "/carsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/carsView/${this.userDatax.objectId}` });
    },

    cardsview() {
      //window.location = "/cardsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/cardsView/${this.userDatax.objectId}` });
    },

    fuelview() {
      //window.location = "/fuelView/" + this.userDatax.objectId;
      this.$router.push({ path: `/fuelView/${this.userDatax.objectId}` });
    },
    profilview() {
      //window.location = "/profilView/" + this.userDatax.objectId;
      this.$router.push({ path: `/profilView/${this.userDatax.objectId}` });
    },
  },
  beforeMount() {
    var userData = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userData.cariKod);
    console.log(userData.objectId);
    this.userDatax = userData;

    console.log("tablo yükleme başladımı");
    const carparams = { cariKod: userData.cariKod };
    const userparams = { cariKod: userData.cariKod };
    console.log(carparams);
    console.log(userparams);

    (async () => {
      console.log("carcount geldi mi");
      try {
        this.countCarsResult = await Parse.Cloud.run("countCars", carparams);
        console.log("countCars   :::  " + this.countCarsResult);
      } catch (error) {
        console.error("Error while retrieving object countCars", error);
      }
    })();

    (async () => {
      console.log("userInfo geldi mi");
      try {
        this.userInfoResult = await Parse.Cloud.run("cariInfo", userparams);
        console.log("UserInfo   :::  " + this.userInfoResult);
        this.cariData = this.userInfoResult[0];
        console.log(this.cariData);

        this.infom = JSON.stringify(this.cariData);
        console.log("infom: " + this.infom);
        localStorage.setItem("Info", JSON.stringify(this.cariData));
        console.log("cariData :: " + JSON.stringify(this.cariData));

        this.cariData = JSON.parse(JSON.stringify(this.cariData));
        console.log("cariData unvan :: " + this.cariData.cariKod);
        console.log(this.cariData.TCKN);
      } catch (error) {
        console.error("Error while retrieving object userInfo", error);
      }
    })();
  },
};
</script>
