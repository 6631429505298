<template>
  <div>
    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Yakıtpay</div>
      <div class="right">
        <a href="#" class="headerButton toggle-searchbox">
          <!--  <ion-icon name="search-outline"></ion-icon> -->
        </a>
      </div>
    </div>
    <!-- * App Header -->

    <div
      class="modal fade modalbox"
      id="ModalBasic"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Size Özel Kampanyalar</h5>
            <a href="#" data-bs-dismiss="modal">Kapat</a>
          </div>
          <div class="modal-body">
            <p>Size Özel Kampanyalar, Yenilikler & Kolaylıklar</p>
            <p>
              Yakıtpay'in her alışverişte kazandıran kampanyalarla dolu fırsat
              dünyasına adım atmak için Yakıtpay mobil uygulamasını
              kullanabilir, ardından da her daim kazanmaya başlayabilirsiniz!
            </p>
            <p>
              Güncel Yakıtpay kampanyalarını mobil uygulamadan takip edebilir,
              sürpriz hediyelerin keyfine varabilirsiniz. Bununla birlikte
              Yakıtpay'e dair en güncel haberler, fırsatlarla dolu Petrol Ofisi
              Dünyası Yakıtpay mobil uygulamasında sizi bekliyor.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modalbox"
      id="ModalTwo"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Petrol Ofisi</h5>
            <a href="#" data-bs-dismiss="modal">Kapat</a>
          </div>
          <div class="modal-body">
            <p>Türkiye’nin en yaygın perakende satış ağı</p>
            <p>
              1900'ü aşkın akaryakıt istasyonu, 1 madeni yağ fabrikası, 8
              akaryakıt, 1 LPG dolum terminali, 20 havaalanı ikmal ünitesi ve
              yaklaşık 1 milyon metreküpü aşan depolama kapasitemizle
              Türkiye’nin lider akaryakıt dağıtım ve madeni yağ şirketiyiz.
            </p>
            <p>
              Müşteri memnuniyetine odaklanan hizmet anlayışı ve üstün nitelikli
              ürünlerimizle tüketicilerin yakıt ihtiyaçlarını güvenle
              karşılamayı; yakıt alışverişini zevkli, hızlı ve pratik bir işlem
              haline dönüştürmeyi hedeflemekteyiz.
            </p>

            <p>
              81 ilin tamamını ve yaklaşık 700 ilçeyi kapsayan geniş bayi ağımız
              ile Türkiye'nin ülke çapında hizmet verebilen en yaygın perakende
              satış ağına sahip akaryakıt şirketiyiz.
            </p>
            <p>
              Müşterilerimize yalnızca akaryakıt ve madeni yağ ürünleri sunmakla
              yetinmiyor, ülke genelinde 1900’ü aşkın istasyonumuzla yolculuk
              sırasında ortaya çıkabilecek farklı ihtiyaçları karşılamaya
              yönelik çözümler sunmaya devam ediyoruz. Bu kapsamda, belirli
              istasyonlarda özel ve perakendecilik alanında iyi bilinen
              markalara yer vererek müşterilerimize sunduğumuz hizmet
              yelpazesini sürekli olarak genişletip seyahatlerin daha konforlu
              geçmesi için çalışıyoruz.
            </p>
            <p>
              Market ve MarketPlus olmak üzere iki farklı market kategorisi ile
              istasyonlarda müşterilerimizin kişisel ihtiyaçlarını ve
              araçlarının gereksinimlerini karşılayabilmelerinin yanı sıra,
              kaliteli ve güvenli alışveriş yapabilecekleri, temiz ve
              rahatlatıcı mekanlar yaratıyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-bottom action-sheet"
      tabindex="-1"
      id="addProvizyonForm"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Provizyon Hazır Limit</h5>
      </div>
      <div class="offcanvas-body">
        <div class="action-sheet-content">
          <form>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="tutar1">Tutar</label>
                <input
                  type="tel"
                  id="cardname1"
                  class="form-control"
                  placeholder="Tutar Giriniz"
                />
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="cardnumber1"
                  >Kart sahibinin adı</label
                >
                <input
                  type="text"
                  id="cardname1"
                  class="form-control"
                  placeholder="Ad Soyad"
                />
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="cardnumber1">Kart numarası</label>
                <input
                  type="tel"
                  id="cardnumber1"
                  class="form-control"
                  placeholder="Kart numarası"
                  v-mask="'#### #### #### ####'"
                  maxlength="19"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label">Ay/Yıl</label>
                    <div class="row">
                      <div class="col-5">
                        <select
                          class="form-control custom-select"
                          id="exp-month"
                        >
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div class="col-7">
                        <select
                          class="form-control custom-select"
                          id="exp-year"
                        >
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2020">2027</option>
                          <option value="2021">2028</option>
                          <option value="2022">2029</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="cardcvv">
                      CVV
                      <a
                        href="#"
                        class="ms-05"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="3-4 digit number back of your card"
                      >
                      </a>
                    </label>
                    <input
                      type="tel"
                      id="cardcvv"
                      class="form-control"
                      placeholder="Güvenlik no"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group basic mt-2">
              <button
                type="button"
                class="btn btn-danger btn-block btn-lg"
                data-bs-dismiss="modal"
                disabled
              >
                Tanımla
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!---->
    <div
      class="offcanvas offcanvas-bottom action-sheet"
      tabindex="-1"
      id="addMoneyForm"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Kredi Kartı ile Para Yükle</h5>
      </div>
      <div class="offcanvas-body">
        <div class="action-sheet-content">
          <form>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="tutar1">Tutar</label>
                <input
                  type="tel"
                  id="cardname1"
                  class="form-control"
                  placeholder="Tutar Giriniz"
                />
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="cardnumber1"
                  >Kart sahibinin adı</label
                >
                <input
                  type="text"
                  id="cardname1"
                  class="form-control"
                  placeholder="Ad Soyad"
                />
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="cardnumber1">Kart numarası</label>
                <input
                  type="tel"
                  id="cardnumber1"
                  class="form-control"
                  placeholder="Kart numarası"
                  v-mask="'#### #### #### ####'"
                  maxlength="19"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label">Ay/Yıl</label>
                    <div class="row">
                      <div class="col-5">
                        <select
                          class="form-control custom-select"
                          id="exp-month"
                        >
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div class="col-7">
                        <select
                          class="form-control custom-select"
                          id="exp-year"
                        >
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2020">2027</option>
                          <option value="2021">2028</option>
                          <option value="2022">2029</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="cardcvv">
                      CVV
                      <a
                        href="#"
                        class="ms-05"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="3-4 digit number back of your card"
                      >
                      </a>
                    </label>
                    <input
                      type="tel"
                      id="cardcvv"
                      class="form-control"
                      placeholder="Güvenlik no"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group basic mt-2">
              <button
                type="button"
                class="btn btn-danger btn-block btn-lg"
                data-bs-dismiss="modal"
                disabled
              >
                Yükle
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!---->

    <!---->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="section mt-1 mb-1">
        <div class="row">
          <div class="col">
            <div class="header-large-title">
              <h3 class="title">Merhaba</h3>
              <h4 class="subtitle">
                {{ cariData.adSoyad }}
              </h4>
            </div>
          </div>

          <div class="col">
            <div class="card border-success bg-success mb-0">
              <div class="card bg-success">
                <div
                  class="card-body mb-0 d-flex align-items-center justify-content-between"
                >
                  <div>
                    <h4 class="card-subtitle">SİSTEM YAKIT</h4>
                    <h3 style="color: white">Alımına Açık</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section mt-1 mb-3">
        <div class="row">
          <div class="col">
            <div class="card border-success mb-0" @click="provizyonDeposit">
              <div class="card-header bg-transparent border-success">
                <ion-icon name="settings-outline"></ion-icon> Provizyon
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card border-success mb-0" @click="cashDeposit">
              <div class="card-header bg-transparent border-danger">
                <ion-icon name="settings-outline"></ion-icon>
                Para Yükle
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- carousel multiple -->
      <!--
      <div class="section full mt-3 mb-3">
        <div class="carousel-multiple splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide">
                <div class="card">
                  <img
                    src="../../../public/frontend/assets/img/sample/photo/d1.jpg"
                    class="card-img-top"
                    alt="image"
                  />
                  <div class="card-body pt-2">
                    <h4 class="mb-0">Alarmnet araç takip'de indirim</h4>
                  </div>
                </div>
              </li>
              <li class="splide__slide">
                <div class="card">
                  <img
                    src="../../../public/frontend/assets/img/sample/photo/d2.jpg"
                    class="card-img-top"
                    alt="image"
                  />
                  <div class="card-body pt-2">
                    <h4 class="mb-0">Trafik sigortasına akaryakıt hediye</h4>
                  </div>
                </div>
              </li>
              <li class="splide__slide">
                <div class="card">
                  <img
                    src="../../../public/frontend/assets/img/sample/photo/d3.jpg"
                    class="card-img-top"
                    alt="image"
                  />
                  <div class="card-body pt-2">
                    <h4 class="mb-0">Araçınız yaza hazır mı?</h4>
                  </div>
                </div>
              </li>

              <li class="splide__slide">
                <div class="card">
                  <img
                    src="../../../public/frontend/assets/img/sample/photo/d6.jpg"
                    class="card-img-top"
                    alt="image"
                  />
                  <div class="card-body pt-2">
                    <h4 class="mb-0">Araç bakımda %10 indirim</h4>
                  </div>
                </div>
              </li>

              <li class="splide__slide">
                <div class="card">
                  <img
                    src="../../../public/frontend/assets/img/sample/photo/d5.jpg"
                    class="card-img-top"
                    alt="image"
                  />
                  <div class="card-body pt-2">
                    <h4 class="mb-0">Araç yıkama indirimi</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    -->
      <!-- * carousel multiple -->

      <div class="section mt-3 mb-3">
        <div class="card">
          <img
            src="../../../public/frontend/assets/img/sample/photo/wide2.jpg"
            class="card-img-top"
            alt="image"
          />
          <div class="card-body">
            <h6 class="card-subtitle">Petrol Ofisi</h6>
            <h5 class="card-title">
              Size Özel Kampanyalar, Yenilikler & Kolaylıklar
            </h5>
            <p class="card-text">
              Yakıtpay her alışverişte kazandıran kampanyalarla dolu fırsat
              dünyasına adım atmak için araçınızı sisteme kaydedebilir, ardından
              da her daim kazanmaya başlayabilirsiniz!
            </p>
            <a
              class="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#ModalBasic"
            >
              <ion-icon name="share-social-outline"></ion-icon>

              Detay
            </a>
          </div>
        </div>
      </div>

      <div class="section mt-3 mb-3">
        <div class="card">
          <img
            src="../../../public/frontend/assets/img/sample/photo/wide4.jpg"
            class="card-img-top"
            alt="image"
          />
          <div class="card-body">
            <h6 class="card-subtitle">PETROL OFİSİ</h6>
            <h5 class="card-title">
              Türkiye’nin en yaygın perakende satış ağı
            </h5>
            <p class="card-text">
              81 ilin tamamını ve yaklaşık 700 ilçeyi kapsayan geniş bayi ağımız
              ile Türkiye'nin ülke çapında hizmet verebilen en yaygın perakende
              satış ağına sahip akaryakıt şirketiyiz.
            </p>
            <a
              class="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#ModalTwo"
            >
              <ion-icon name="share-social-outline"></ion-icon>

              Detay
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- * App Capsule -->

    <!-- App Bottom Menu -->
    <div class="section full mt-2">
      <!-- App Bottom Menu -->

      <div class="appBottomMenu">
        <a class="item active">
          <div class="col">
            <router-link :to="{ path: '/mainView/' + userDatax.objectId }">
              <ion-icon name="home-outline"></ion-icon>
              <strong>Anasayfa</strong>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/mapView/' }">
              <ion-icon name="navigate-outline"></ion-icon>
              <strong>İstasyonlar</strong>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <div class="action-button large danger">
              <!--   <ion-icon name="add-outline"></ion-icon>-->
              <img
                src="../../../public/frontend/assets/img/po.png"
                alt="image"
              />
            </div>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/carsView/' + userDatax.objectId }">
              <ion-icon name="car-sport-outline"></ion-icon>
              <strong>Araçlarım</strong>
              <span class="badge badge-danger">{{ this.countCarsResult }}</span>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/cardsView/' + userDatax.objectId }">
              <ion-icon name="card-outline"></ion-icon>

              <strong>Kartlarım</strong>
            </router-link>
          </div>
        </a>
      </div>
      <!-- * App Bottom Menu -->
    </div>

    <!-- App Sidebar -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
      <div class="offcanvas-body">
        <!-- profile box -->
        <div class="profileBox">
          <div class="image-wrapper">
            <img
              src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
              alt="image"
              class="imaged rounded"
            />
          </div>
          <div class="in">
            <strong>{{ cariData.adSoyad }}</strong>
            <div class="text-muted"></div>
          </div>
          <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <!-- * profile box -->

        <ul class="listview flush transparent no-line image-listview mt-2">
          <li>
            <a class="item" href="#" @click.prevent="profilview">
              <div class="icon-box bg-danger">
                <ion-icon name="person-outline"></ion-icon>
              </div>
              <div class="in">Profil</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="fuelview">
              <div class="icon-box bg-danger">
                <ion-icon name="speedometer-outline"></ion-icon>
              </div>
              <div class="in">Yakıt Alımları</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="cardsview">
              <div class="icon-box bg-danger">
                <ion-icon name="card-outline"></ion-icon>
              </div>
              <div class="in">Kredi Kartı Bilgileri</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="carsview">
              <div class="icon-box bg-danger">
                <ion-icon name="car-outline"></ion-icon>
              </div>
              <div class="in">
                Araç Bilgileri
                <span class="badge badge-danger">{{
                  this.countCarsResult
                }}</span>
              </div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="callUs">
              <div class="icon-box bg-danger">
                <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
              </div>
              Bize Ulaşın
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="logoutUser">
              <div class="icon-box bg-danger">
                <ion-icon name="exit-outline"></ion-icon>
              </div>
              Çıkış
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- * App Sidebar -->
  </div>
</template>
<script src="/frontend/assets/js/plugins/splide/splide.min.js"></script>
<script>
import Parse from "parse";
import { RouterView } from "vue-router";
// @ is an alias to /src
export default {
  name: "MainView",

  components: {},
  data() {
    return {
      countCarsResult: 0,
      userInfoResult: [],
      cariData: "",
      info: "",
      cash: true,
      provizyon: true,
      userDatax: [],
    };
  },

  methods: {
    goBack() {},

    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/"); //DİKKAT
    },

    mainView() {
      //window.location = "/mainVew/" + this.userDatax.objectId;

      this.$router.push({ path: `/mainView/${this.userDatax.objectId}` });
    },

    callUs() {
      //window.location = "/contactview";
      this.$router.push({ path: `/contactview` });
    },

    carsview() {
      //window.location = "/carsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/carsView/${this.userDatax.objectId}` });
    },

    cardsview() {
      //window.location = "/cardsView/" + this.userDatax.objectId;
      this.$router.push({ path: `/cardsView/${this.userDatax.objectId}` });
    },

    fuelview() {
      //window.location = "/fuelView/" + this.userDatax.objectId;
      this.$router.push({ path: `/fuelView/${this.userDatax.objectId}` });
    },
    profilview() {
      //window.location = "/profilView/" + this.userDatax.objectId;
      this.$router.push({ path: `/profilView/${this.userDatax.objectId}` });
    },

    cashDeposit() {
      console.log("para yatırma");
      this.cash = false;
      var myOffcanvas = document.getElementById("addMoneyForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();
    },

    provizyonDeposit() {
      console.log("provizyon limit");
      this.provizyon = false;
      var myOffcanvas = document.getElementById("addProvizyonForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();
    },
  },
  beforeMount() {
    var userData = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userData.cariKod);
    console.log(userData.objectId);
    this.userDatax = userData;

    console.log("tablo yükleme başladımı");
    const carparams = { cariKod: userData.cariKod };
    const userparams = { cariKod: userData.cariKod };
    console.log(carparams);
    console.log(userparams);

    (async () => {
      console.log("carcount geldi mi");
      try {
        this.countCarsResult = await Parse.Cloud.run("countCars", carparams);
        console.log("countCars   :::  " + this.countCarsResult);
      } catch (error) {
        console.error("Error while retrieving object countCars", error);
      }
    })();

    (async () => {
      console.log("userInfo geldi mi");
      try {
        this.userInfoResult = await Parse.Cloud.run("cariInfo", userparams);
        console.log("UserInfo   :::  " + this.userInfoResult);
        this.cariData = this.userInfoResult[0];

        //console.log("UserInfo   :::  " + this.userInfoResult.ticariUnvan);
      } catch (error) {
        console.error("Error while retrieving object userInfo", error);
      }

      this.infom = JSON.stringify(this.cariData);
      console.log("infom: " + this.infom);
      localStorage.setItem("Info", JSON.stringify(this.cariData));
      console.log("cariData :: " + JSON.stringify(this.cariData));
      this.cariData = JSON.parse(JSON.stringify(this.cariData));
      console.log("cariData unvan :: " + this.cariData.cariKod);
    })();
  },
};
</script>
